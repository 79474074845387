import React, { Component } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import BackLink from '../components/BackLink';
import Layout from '../components/Layout';


import MEDIA_QUERY from '../constants/media-queries';

const WrapDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin-top: 2em;

  ${MEDIA_QUERY.M} {
    flex-direction: row;
    justify-content: center;
  }
`;

const BookImage = styled.img`
  max-width: 80vw;
  max-height: 75vh;
  object-fit: contain;
`;

const BookDetails = styled.div`
  padding: 0.5em;
  width: 100%;
  margin-right: auto;

  ${MEDIA_QUERY.M} {
    padding: 3em;
    width: auto;
  }
`;

export default class Book extends Component {
  render() {
    const data = this.props.data.allGoogleSpreadsheetContentWebContent.edges[0].node;
    const { authorseditors, year, city, isbn, publishers, name, imagelink } = data;

    return (
      <Layout location={this.props.location}>
        <WrapDiv>
          <BackLink />
          <BookImage src={imagelink} />
          <BookDetails>
            <h3>{name}</h3>
            <h4>{publishers}</h4>
            <div>
              <div>{authorseditors}</div>
              <div>{city}</div>
              <div>{year}</div>
              <div>{isbn}</div>
            </div>
          </BookDetails>
        </WrapDiv>
      </Layout>
    );
  }
}

export const bookQuery = graphql`
  query BookById($id: String!) {
    allGoogleSpreadsheetContentWebContent(
      filter: {id: {eq: $id}}
    ) {
        edges {
          node {
            authorseditors: author_s__editor_s_
            year
            city
            isbn
            publishers: publisher_s_
            name
            imagelink: imageLink
          }
        }
      }
  }
`;
