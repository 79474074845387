
import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import BackIcon from './icons/BackIcon';

import MEDIA_QUERY from '../constants/media-queries';

const StyledBackIconLink = styled(Link)`
  display: none;
  margin-right: none;
  padding: 3em;

  path {
    fill: #D1D1D1;
  }

  &:hover path {
    fill: #3D58ED;
  }

  ${MEDIA_QUERY.S} {
    display: block;
  }

  ${MEDIA_QUERY.M} {
    margin-right: auto;
  }
`;


export default () => (
  <StyledBackIconLink to='/'>
    <BackIcon />
  </StyledBackIconLink>
);
